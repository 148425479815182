@charset "UTF-8";
/* Imports */
/* Fonts */
@import 'https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,700,700i';
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:400,400i,700,700i");
/* Grid */
/* Breakpoints */
/* Menu settings*/
/* Primary colors */
/* Additional colors */
/* Font settings */
/* PX TO EM */
* {
  box-sizing: border-box;
  outline: none; }

body,
html {
  margin: 0;
  padding: 0;
  min-height: 100%; }

body {
  max-width: 100%;
  overflow-x: hidden; }

.ccm-page {
  padding: 0;
  line-height: 1.6;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #414042;
  /* Grid */ }
  .ccm-page h1,
  .ccm-page h2,
  .ccm-page h3,
  .ccm-page p,
  .ccm-page table,
  .ccm-page ol,
  .ccm-page ul {
    margin: 0 0 15px;
    padding: 0; }
  .ccm-page h1,
  .ccm-page h2,
  .ccm-page h3 {
    line-height: 1.2;
    font-family: "Playfair Display", serif;
    color: #57996a;
    position: relative;
    display: inline-block; }
    .ccm-page h1::after,
    .ccm-page h2::after,
    .ccm-page h3::after {
      width: 100%;
      height: 2px;
      background: #57996a;
      position: absolute;
      bottom: -1px;
      left: 0; }
  .ccm-page h2, .ccm-page h3, .ccm-page h4 {
    margin-bottom: 0; }
    .ccm-page h2::after, .ccm-page h3::after, .ccm-page h4::after {
      width: 100%;
      height: 1px;
      background: #57996a;
      position: absolute;
      bottom: 0;
      left: 0; }
  .ccm-page h2 {
    font-size: 24px; }
  .ccm-page h3 {
    font-size: 20px; }
  .ccm-page h4 {
    font-size: 19px; }
  .ccm-page h1 {
    font-size: 44px;
    margin-bottom: 30px; }
  .ccm-page ol,
  .ccm-page ul {
    list-style: none; }
  .ccm-page a {
    color: inherit; }
  .ccm-page img {
    width: 100%;
    height: auto;
    max-width: 100%;
    vertical-align: bottom; }
    @media (min-width: 768px) {
      .ccm-page img {
        width: auto; } }
  .ccm-page .pull-left {
    float: left !important; }
  .ccm-page .pull-right {
    float: right !important; }
  .ccm-page .text-left {
    text-align: left !important; }
  .ccm-page .text-center {
    text-align: center !important; }
  .ccm-page .text-right {
    text-align: right !important; }
  .ccm-page .row {
    display: block;
    margin: 0 auto;
    padding: 0;
    max-width: 1000px; }
    .ccm-page .row:after {
      display: table;
      content: '';
      clear: both; }
    .ccm-page .row.full {
      max-width: none; }
    .ccm-page .row .column {
      float: left;
      display: block;
      margin: 0;
      padding: 0 10px;
      width: 100%; }
      .ccm-page .row .column .row {
        margin-left: -10px;
        margin-right: -10px; }
    .ccm-page .row .base-1 {
      width: 8.33333%; }
    .ccm-page .row .base-2 {
      width: 16.66667%; }
    .ccm-page .row .base-3 {
      width: 25%; }
    .ccm-page .row .base-4 {
      width: 33.33333%; }
    .ccm-page .row .base-5 {
      width: 41.66667%; }
    .ccm-page .row .base-6 {
      width: 50%; }
    .ccm-page .row .base-7 {
      width: 58.33333%; }
    .ccm-page .row .base-8 {
      width: 66.66667%; }
    .ccm-page .row .base-9 {
      width: 75%; }
    .ccm-page .row .base-10 {
      width: 83.33333%; }
    .ccm-page .row .base-11 {
      width: 91.66667%; }
    .ccm-page .row .base-12 {
      width: 100%; }
    @media only screen and (min-width: 480px) {
      .ccm-page .row .small-1 {
        width: 8.33333%; }
      .ccm-page .row .small-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .small-2 {
        width: 16.66667%; }
      .ccm-page .row .small-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .small-3 {
        width: 25%; }
      .ccm-page .row .small-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .small-4 {
        width: 33.33333%; }
      .ccm-page .row .small-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .small-5 {
        width: 41.66667%; }
      .ccm-page .row .small-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .small-6 {
        width: 50%; }
      .ccm-page .row .small-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .small-7 {
        width: 58.33333%; }
      .ccm-page .row .small-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .small-8 {
        width: 66.66667%; }
      .ccm-page .row .small-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .small-9 {
        width: 75%; }
      .ccm-page .row .small-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .small-10 {
        width: 83.33333%; }
      .ccm-page .row .small-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .small-11 {
        width: 91.66667%; }
      .ccm-page .row .small-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .small-12 {
        width: 100%; }
      .ccm-page .row .small-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 768px) {
      .ccm-page .row .medium-1 {
        width: 8.33333%; }
      .ccm-page .row .medium-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .medium-2 {
        width: 16.66667%; }
      .ccm-page .row .medium-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .medium-3 {
        width: 25%; }
      .ccm-page .row .medium-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .medium-4 {
        width: 33.33333%; }
      .ccm-page .row .medium-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .medium-5 {
        width: 41.66667%; }
      .ccm-page .row .medium-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .medium-6 {
        width: 50%; }
      .ccm-page .row .medium-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .medium-7 {
        width: 58.33333%; }
      .ccm-page .row .medium-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .medium-8 {
        width: 66.66667%; }
      .ccm-page .row .medium-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .medium-9 {
        width: 75%; }
      .ccm-page .row .medium-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .medium-10 {
        width: 83.33333%; }
      .ccm-page .row .medium-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .medium-11 {
        width: 91.66667%; }
      .ccm-page .row .medium-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .medium-12 {
        width: 100%; }
      .ccm-page .row .medium-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1024px) {
      .ccm-page .row .large-1 {
        width: 8.33333%; }
      .ccm-page .row .large-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .large-2 {
        width: 16.66667%; }
      .ccm-page .row .large-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .large-3 {
        width: 25%; }
      .ccm-page .row .large-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .large-4 {
        width: 33.33333%; }
      .ccm-page .row .large-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .large-5 {
        width: 41.66667%; }
      .ccm-page .row .large-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .large-6 {
        width: 50%; }
      .ccm-page .row .large-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .large-7 {
        width: 58.33333%; }
      .ccm-page .row .large-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .large-8 {
        width: 66.66667%; }
      .ccm-page .row .large-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .large-9 {
        width: 75%; }
      .ccm-page .row .large-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .large-10 {
        width: 83.33333%; }
      .ccm-page .row .large-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .large-11 {
        width: 91.66667%; }
      .ccm-page .row .large-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .large-12 {
        width: 100%; }
      .ccm-page .row .large-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1200px) {
      .ccm-page .row .xlarge-1 {
        width: 8.33333%; }
      .ccm-page .row .xlarge-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .xlarge-2 {
        width: 16.66667%; }
      .ccm-page .row .xlarge-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .xlarge-3 {
        width: 25%; }
      .ccm-page .row .xlarge-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .xlarge-4 {
        width: 33.33333%; }
      .ccm-page .row .xlarge-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .xlarge-5 {
        width: 41.66667%; }
      .ccm-page .row .xlarge-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .xlarge-6 {
        width: 50%; }
      .ccm-page .row .xlarge-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .xlarge-7 {
        width: 58.33333%; }
      .ccm-page .row .xlarge-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .xlarge-8 {
        width: 66.66667%; }
      .ccm-page .row .xlarge-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .xlarge-9 {
        width: 75%; }
      .ccm-page .row .xlarge-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .xlarge-10 {
        width: 83.33333%; }
      .ccm-page .row .xlarge-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .xlarge-11 {
        width: 91.66667%; }
      .ccm-page .row .xlarge-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .xlarge-12 {
        width: 100%; }
      .ccm-page .row .xlarge-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1824px) {
      .ccm-page .row .xxlarge-1 {
        width: 8.33333%; }
      .ccm-page .row .xxlarge-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .xxlarge-2 {
        width: 16.66667%; }
      .ccm-page .row .xxlarge-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .xxlarge-3 {
        width: 25%; }
      .ccm-page .row .xxlarge-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .xxlarge-4 {
        width: 33.33333%; }
      .ccm-page .row .xxlarge-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .xxlarge-5 {
        width: 41.66667%; }
      .ccm-page .row .xxlarge-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .xxlarge-6 {
        width: 50%; }
      .ccm-page .row .xxlarge-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .xxlarge-7 {
        width: 58.33333%; }
      .ccm-page .row .xxlarge-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .xxlarge-8 {
        width: 66.66667%; }
      .ccm-page .row .xxlarge-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .xxlarge-9 {
        width: 75%; }
      .ccm-page .row .xxlarge-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .xxlarge-10 {
        width: 83.33333%; }
      .ccm-page .row .xxlarge-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .xxlarge-11 {
        width: 91.66667%; }
      .ccm-page .row .xxlarge-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .xxlarge-12 {
        width: 100%; }
      .ccm-page .row .xxlarge-offset-12 {
        margin-left: 100% !important; } }
    @media only screen and (min-width: 1224px) {
      .ccm-page .row .menuswitch-1 {
        width: 8.33333%; }
      .ccm-page .row .menuswitch-offset-1 {
        margin-left: 8.33333% !important; }
      .ccm-page .row .menuswitch-2 {
        width: 16.66667%; }
      .ccm-page .row .menuswitch-offset-2 {
        margin-left: 16.66667% !important; }
      .ccm-page .row .menuswitch-3 {
        width: 25%; }
      .ccm-page .row .menuswitch-offset-3 {
        margin-left: 25% !important; }
      .ccm-page .row .menuswitch-4 {
        width: 33.33333%; }
      .ccm-page .row .menuswitch-offset-4 {
        margin-left: 33.33333% !important; }
      .ccm-page .row .menuswitch-5 {
        width: 41.66667%; }
      .ccm-page .row .menuswitch-offset-5 {
        margin-left: 41.66667% !important; }
      .ccm-page .row .menuswitch-6 {
        width: 50%; }
      .ccm-page .row .menuswitch-offset-6 {
        margin-left: 50% !important; }
      .ccm-page .row .menuswitch-7 {
        width: 58.33333%; }
      .ccm-page .row .menuswitch-offset-7 {
        margin-left: 58.33333% !important; }
      .ccm-page .row .menuswitch-8 {
        width: 66.66667%; }
      .ccm-page .row .menuswitch-offset-8 {
        margin-left: 66.66667% !important; }
      .ccm-page .row .menuswitch-9 {
        width: 75%; }
      .ccm-page .row .menuswitch-offset-9 {
        margin-left: 75% !important; }
      .ccm-page .row .menuswitch-10 {
        width: 83.33333%; }
      .ccm-page .row .menuswitch-offset-10 {
        margin-left: 83.33333% !important; }
      .ccm-page .row .menuswitch-11 {
        width: 91.66667%; }
      .ccm-page .row .menuswitch-offset-11 {
        margin-left: 91.66667% !important; }
      .ccm-page .row .menuswitch-12 {
        width: 100%; }
      .ccm-page .row .menuswitch-offset-12 {
        margin-left: 100% !important; } }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #CCC; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.ccm-page .alert {
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: 2px; }
  .ccm-page .alert.alert-info {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #5bc0de; }
  .ccm-page .alert.alert-success {
    background-color: #dff0d8;
    border-color: #d6e9c6;
    color: #5cb85c; }
  .ccm-page .alert.alert-danger {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #d9534f; }
  .ccm-page .alert.alert-warning {
    background-color: #fcf8e3;
    border-color: #faf2cc;
    color: #f0ad4e; }

.ccm-page table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  width: 100%; }
  .ccm-page table tr td,
  .ccm-page table tr th {
    padding: 8px;
    vertical-align: middle;
    text-align: left; }
  .ccm-page table tr td {
    border-top: 1px solid #ddd; }
  .ccm-page table tr:last-child td {
    border-bottom: 1px solid #ddd; }
  .ccm-page table.table-striped tr:nth-child(odd) {
    background: #c0c0c0; }

.ccm-page .form-group {
  display: block;
  margin: 0 0 15px; }

.ccm-page .form-control {
  outline: none;
  border: 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  margin: 0;
  padding: 0 15px;
  background: #c0c0c0;
  width: 100%;
  height: 45px;
  line-height: 45px;
  font-family: inherit;
  font-size: inherit;
  color: #414042; }

.ccm-page .control-label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer; }

.ccm-page textarea.form-control {
  resize: none;
  padding: 10px 15px;
  height: auto;
  line-height: 1.6; }

.ccm-page .ccm-input-date {
  margin-right: 0 !important; }

.ccm-page .input-group:after {
  display: table;
  content: '';
  clear: both; }

.ccm-page .input-group .input-group-datepicker {
  float: left;
  width: calc(100% - 45px); }
  .ccm-page .input-group .input-group-datepicker .form-control {
    width: 100%; }

.ccm-page .input-group .input-group-addon {
  display: block;
  border: 0;
  border-radius: 3px;
  background: #ddd;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-size: 14px;
  color: rgba(65, 64, 66, 0.3); }
  .ccm-page .input-group .input-group-addon:first-child {
    float: left;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ccm-page .input-group .input-group-addon:last-child {
    float: right;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.ccm-page .input-group .form-control {
  width: calc(100% - 45px); }
  .ccm-page .input-group .form-control:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .ccm-page .input-group .form-control:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.ccm-page .button {
  display: inline-block;
  border: 0;
  text-decoration: none;
  text-align: center;
  background: #636b77;
  color: white;
  font-family: "Playfair Display", serif;
  position: relative;
  z-index: 1;
  padding: 0px 10px;
  text-transform: uppercase;
  padding: 10px 30px;
  font-size: 16px; }
  .ccm-page .button::before {
    position: absolute;
    right: -3px;
    bottom: -3px;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    border-right: 3px solid #57996a;
    border-bottom: 3px solid #57996a;
    content: ''; }
  .ccm-page .button::after {
    font-family: 'FontAwesome';
    content: '\f061';
    margin-left: 10px; }
  .ccm-page .button:hover::before {
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-right: 3px solid #414042;
    border-bottom: 3px solid #414042; }

.ccm-page .button-primary {
  background-color: #57996a;
  color: #fff; }

.ccm-page .button-success {
  background-color: #5cb85c;
  color: #fff; }

.ccm-page .button-info {
  background-color: #5bc0de;
  color: #fff; }

.ccm-page .button-warning {
  background-color: #f0ad4e;
  color: #fff; }

.ccm-page .button-danger {
  background-color: #d9534f;
  color: #fff; }

.ccm-page .button-link {
  background-color: transparent;
  color: #57996a; }

.ccm-page .label {
  display: inline;
  padding: 2px 6px;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 2px; }
  .ccm-page .label.label-default {
    background-color: #fff; }
  .ccm-page .label.label-primary {
    background-color: #57996a; }
  .ccm-page .label.label-danger {
    background-color: #d9534f; }
  .ccm-page .label.label-info {
    background-color: #5bc0de; }
  .ccm-page .label.label-success {
    background-color: #5cb85c; }
  .ccm-page .label.label-warning {
    background-color: #f0ad4e; }

/* Bring the magic */
.ccm-page {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .ccm-page .fullwidth {
    max-width: 100%; }
  .ccm-page.page-template-homepage .header {
    border-bottom: 0; }
    .ccm-page.page-template-homepage .header::before {
      display: none; }
  .ccm-page .header {
    position: fixed;
    top: 0;
    background: white;
    z-index: 22;
    width: 100%; }
    @media only screen and (min-width: 768px) {
      .ccm-page .header {
        position: relative; } }
    .ccm-page .header:before {
      width: 100%;
      position: absolute;
      height: 1px;
      z-index: -1;
      background: #ededed;
      bottom: -10px;
      left: 0;
      content: ''; }
    .ccm-page .header .logo-wrapper {
      max-width: 220px; }
    .ccm-page .header .menu-toggle {
      right: 10px;
      position: absolute;
      top: 18px;
      font-size: 28px; }
    .ccm-page .header nav {
      display: none;
      position: relative;
      z-index: 10; }
      .ccm-page .header nav ul.menu {
        background-color: white;
        text-align: center;
        margin: 0px;
        margin-top: 35px; }
        .ccm-page .header nav ul.menu li {
          position: relative;
          display: inline-block;
          padding: 0 10px;
          width: 100%;
          margin: 0px 1px;
          height: auto;
          line-height: 40px;
          background-color: transparant;
          border-top: 1px solid #ececec; }
          .ccm-page .header nav ul.menu li a {
            text-decoration: none;
            color: black;
            height: 100%;
            width: 100%;
            display: inline-block;
            font-family: "Playfair Display", serif;
            color: #6e7682;
            text-transform: uppercase;
            position: relative;
            font-size: 16px;
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out; }
            .ccm-page .header nav ul.menu li a:hover {
              -webkit-transition: all .3s ease-in-out;
              -moz-transition: all .3s ease-in-out;
              -o-transition: all .3s ease-in-out;
              transition: all .3s ease-in-out;
              color: #414042; }
            .ccm-page .header nav ul.menu li a::after {
              width: 1px;
              height: 17px;
              top: 13px;
              content: "";
              background: #ededed;
              position: absolute;
              right: -11px; }
            .ccm-page .header nav ul.menu li a i {
              width: 25px; }
          .ccm-page .header nav ul.menu li.menu__item--current a {
            background: #636b77;
            color: white;
            position: relative;
            z-index: 1;
            padding: 0px 10px; }
            .ccm-page .header nav ul.menu li.menu__item--current a::before {
              position: absolute;
              right: -3px;
              bottom: -3px;
              width: calc(100% - 3px);
              height: calc(100% - 3px);
              border-right: 3px solid #57996a;
              border-bottom: 3px solid #57996a;
              content: ''; }
          .ccm-page .header nav ul.menu li.menu__item--current:hover a::before {
            -webkit-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            border-right: 3px solid #414042;
            border-bottom: 3px solid #414042; }
          .ccm-page .header nav ul.menu li ul.menu__submenu {
            display: none;
            margin-bottom: 0px; }
            @media only screen and (min-width: 1224px) {
              .ccm-page .header nav ul.menu li ul.menu__submenu {
                position: absolute;
                top: 55px;
                left: 0px; } }
          .ccm-page .header nav ul.menu li.drop-down-menu {
            padding: 0px;
            position: relative; }
            @media only screen and (min-width: 1224px) {
              .ccm-page .header nav ul.menu li.drop-down-menu {
                padding: 0 10px;
                padding-right: 25px; } }
            .ccm-page .header nav ul.menu li.drop-down-menu ul li {
              min-width: 100%; }
              .ccm-page .header nav ul.menu li.drop-down-menu ul li a {
                font-style: italic; }
            @media only screen and (min-width: 1224px) {
              .ccm-page .header nav ul.menu li.drop-down-menu:hover ul.menu__submenu {
                display: block; } }
            .ccm-page .header nav ul.menu li.drop-down-menu .open-dropdown {
              color: black;
              background-color: #fff;
              position: absolute;
              top: 0;
              right: 0;
              width: 25px;
              height: calc(55px - 1);
              line-height: 55px; }
              @media only screen and (min-width: 1224px) {
                .ccm-page .header nav ul.menu li.drop-down-menu .open-dropdown {
                  background-color: transparent; } }
          @media only screen and (min-width: 1224px) {
            .ccm-page .header nav ul.menu li {
              width: auto;
              border-top: none; } }
      @media only screen and (min-width: 1224px) {
        .ccm-page .header nav {
          display: block; } }
    .ccm-page .header .menu-toggle {
      display: inline-block;
      margin: 0 auto; }
      @media only screen and (min-width: 1224px) {
        .ccm-page .header .menu-toggle {
          display: none; } }
  .ccm-page .ccm-image-slider {
    position: relative;
    top: -10px; }
    @media only screen and (min-width: 768px) {
      .ccm-page .ccm-image-slider {
        display: block; } }
    .ccm-page .ccm-image-slider .rslides li {
      background-size: cover;
      background-position: center;
      min-height: 280px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .ccm-image-slider .rslides li {
          min-height: 500px; } }
    .ccm-page .ccm-image-slider .rslides_nav {
      background: white;
      left: 10px;
      width: 30px;
      height: 30px;
      top: 65%;
      border-radius: 50%;
      position: absolute;
      opacity: 1; }
      @media only screen and (min-width: 768px) {
        .ccm-page .ccm-image-slider .rslides_nav {
          left: 20px;
          width: 60px;
          height: 60px;
          top: 50%; } }
      .ccm-page .ccm-image-slider .rslides_nav::before {
        font-family: FontAwesome;
        color: #636b77;
        content: "\f053";
        font-size: 20px;
        line-height: 1.6;
        margin-left: 7px; }
        @media only screen and (min-width: 768px) {
          .ccm-page .ccm-image-slider .rslides_nav::before {
            line-height: 2;
            font-size: 32px;
            margin-left: 16px; } }
      .ccm-page .ccm-image-slider .rslides_nav.next {
        left: auto;
        right: 10px; }
        @media only screen and (min-width: 768px) {
          .ccm-page .ccm-image-slider .rslides_nav.next {
            right: 20px; } }
        .ccm-page .ccm-image-slider .rslides_nav.next::before {
          margin-right: 5px;
          content: "\f054"; }
          @media only screen and (min-width: 768px) {
            .ccm-page .ccm-image-slider .rslides_nav.next::before {
              margin-left: 21px; } }
    .ccm-page .ccm-image-slider .rslides_tabs {
      margin: 0 auto;
      text-align: center;
      left: 0;
      position: absolute;
      right: 0;
      bottom: 10px;
      z-index: 2; }
      .ccm-page .ccm-image-slider .rslides_tabs li {
        display: inline-block;
        border: 2px solid white;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        margin-right: 7px;
        cursor: pointer; }
        .ccm-page .ccm-image-slider .rslides_tabs li a {
          background: none; }
        .ccm-page .ccm-image-slider .rslides_tabs li.rslides_here {
          background: white;
          border: 2px solid white; }
    .ccm-page .ccm-image-slider .ccm-image-slider-text {
      margin: 0 auto;
      width: 100%;
      max-width: 800px;
      left: 0;
      right: 0;
      text-align: center;
      text-shadow: 0px 1px 2px #464646;
      max-width: 840px;
      top: 130px; }
      @media only screen and (min-width: 768px) {
        .ccm-page .ccm-image-slider .ccm-image-slider-text {
          top: 200px; } }
      .ccm-page .ccm-image-slider .ccm-image-slider-text h2 {
        color: white;
        font-size: 24px;
        font-family: "Playfair Display", serif;
        position: relative;
        padding: 0 50px; }
        @media only screen and (min-width: 768px) {
          .ccm-page .ccm-image-slider .ccm-image-slider-text h2 {
            font-size: 58px;
            position: relative;
            padding: 0 20px; } }
        .ccm-page .ccm-image-slider .ccm-image-slider-text h2::before {
          top: -70px;
          margin: 0 auto;
          left: 0;
          width: 60px;
          height: 64px;
          right: 0;
          position: absolute;
          background: url("../images/leave.png"); }
        .ccm-page .ccm-image-slider .ccm-image-slider-text h2::after {
          display: none; }
  .ccm-page .main {
    clear: both;
    margin-top: 30px; }
    @media only screen and (min-width: 768px) {
      .ccm-page .main {
        margin-top: 30px; } }
    .ccm-page .main canvas {
      float: left;
      margin-right: 5px; }
    .ccm-page .main img {
      margin-bottom: 10px; }
    .ccm-page .main .large-8 img {
      float: left;
      margin-right: 10px; }
    .ccm-page .main .homepage {
      margin-top: 40px; }
      .ccm-page .main .homepage .top-content {
        text-align: center; }
        .ccm-page .main .homepage .top-content p {
          font-size: 16px; }
        .ccm-page .main .homepage .top-content h1 {
          position: relative;
          background: white;
          display: inline-block; }
          .ccm-page .main .homepage .top-content h1::after {
            width: 100%;
            height: 2px;
            background: #57996a;
            position: absolute;
            bottom: -1px;
            left: 0; }
        .ccm-page .main .homepage .top-content .bottom p {
          font-size: 14px; }
        .ccm-page .main .homepage .top-content .bottom h2, .ccm-page .main .homepage .top-content .bottom h3 {
          margin-bottom: 10px; }
  .ccm-page .contact-form .text-area-lg textarea {
    height: 10rem; }
  .ccm-page .contact-form .human-check-wrap {
    background-color: transparent;
    color: #414042;
    padding-left: 0px;
    margin-top: -50px;
    margin-bottom: -80px; }
    .ccm-page .contact-form .human-check-wrap span.dynamic-form-error {
      left: 173px; }
  .ccm-page .contact-form span.dynamic-form-error {
    top: 0px; }
  .ccm-page .image-slide {
    margin-top: 70px; }
    .ccm-page .image-slide .image-holder {
      height: 300px;
      background-size: cover;
      margin-right: 1px; }
  .ccm-page .home-columns {
    margin-top: 40px;
    margin-bottom: 40px; }
    .ccm-page .home-columns .content {
      background: #57996a;
      color: white;
      padding: 25px 20px;
      text-align: center; }
      .ccm-page .home-columns .content h2, .ccm-page .home-columns .content h3 {
        text-transform: uppercase;
        font-size: 19px;
        margin-bottom: 20px; }
      .ccm-page .home-columns .content h2, .ccm-page .home-columns .content h3, .ccm-page .home-columns .content p {
        color: white; }
      .ccm-page .home-columns .content p {
        font-size: 14px; }
    .ccm-page .home-columns .slick-next:before, .ccm-page .home-columns .slick-prev:before {
      font-size: 30px;
      line-height: 1;
      color: #636b77;
      opacity: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale; }
    .ccm-page .home-columns .slick-next {
      right: -30px; }
    .ccm-page .home-columns .slick-prev {
      left: -40px; }
    .ccm-page .home-columns .slick-dots {
      position: absolute;
      margin: 0 auto;
      left: 0;
      right: 0; }
      @media only screen and (min-width: 768px) {
        .ccm-page .home-columns .slick-dots {
          display: block; } }
      .ccm-page .home-columns .slick-dots li {
        display: inline-block;
        border: 2px solid white;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        margin-right: 1px;
        cursor: pointer; }
        .ccm-page .home-columns .slick-dots li.slick-active {
          background: white;
          border: 2px solid white; }
        .ccm-page .home-columns .slick-dots li button {
          opacity: 0; }
    .ccm-page .home-columns .column h2::before {
      font-family: 'FontAwesome';
      content: '\f086';
      margin-right: 5px; }
    .ccm-page .home-columns .column:first-child p {
      font-style: italic; }
    .ccm-page .home-columns .column:nth-of-type(2) h2::before {
      content: '\f1ae'; }
    .ccm-page .home-columns .column:nth-of-type(3) h2::before {
      content: '\f015'; }
  .ccm-page .footer {
    margin-top: 20px;
    background: #c0c0c0;
    padding: 30px 0;
    min-height: 380px;
    background: url("../images/huisje.jpg");
    background-size: cover;
    background-position: center; }
    .ccm-page .footer h2 {
      font-size: 24px; }
    .ccm-page .footer p {
      font-size: 16px;
      line-height: 1.8; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .footer {
        min-height: 680px; } }
    .ccm-page .footer .footer-bottom-left,
    .ccm-page .footer .footer-bottom-right {
      text-align: left;
      line-height: 30px; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .footer .footer-bottom-left,
        .ccm-page .footer .footer-bottom-right {
          text-align: right; } }
    .ccm-page .footer .footer-bottom-left {
      text-align: center; }
      @media only screen and (min-width: 1024px) {
        .ccm-page .footer .footer-bottom-left {
          text-align: left; } }
  .ccm-page .copyright {
    font-size: 13px;
    margin-top: 10px; }
    @media only screen and (min-width: 1024px) {
      .ccm-page .copyright .footer-bottom-right {
        text-align: right; } }

.breadcrumb-warp {
  width: 100%;
  margin-top: 0;
  margin-bottom: 20px;
  position: relative;
  display: inline-block; }
  .breadcrumb-warp ul li {
    float: left;
    color: #c0c0c0;
    font-size: 14px; }
    .breadcrumb-warp ul li a {
      text-decoration: none; }
      .breadcrumb-warp ul li a .fa-home {
        color: #c0c0c0; }
      .breadcrumb-warp ul li a:hover {
        text-decoration: underline; }
    .breadcrumb-warp ul li:last-child::after {
      display: none; }
    .breadcrumb-warp ul li::after {
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      content: '/';
      display: inline-block;
      width: 15px;
      margin-left: 10px; }

/* PARALLAX*/
